import Vue from 'vue';
import { createApp } from 'vue';
import App from './App.vue';
import { Amplify } from 'aws-amplify';
import awsExports from './aws-exports';
Amplify.configure(awsExports);
import router from "./router/router";
import 'bootstrap/dist/css/bootstrap.css'
import '../src/assets/css/custom_bootstrap.css';
import vue3GoogleLogin from 'vue3-google-login';
import Vue3Storage, { StorageType } from "vue3-storage";
import Toaster from "@meforma/vue-toaster";
import XLSX from 'xlsx';

// fontawesome for icons
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faArrowDown, faArrowTurnDown, faArrowTurnUp, faArrowUp, faCircleExclamation, faCircleInfo, faEnvelope, faXmark } from '@fortawesome/free-solid-svg-icons'
import { faCopy } from '@fortawesome/free-regular-svg-icons';
library.add(faXmark, faCircleExclamation, faCircleInfo, faEnvelope, faCopy, faArrowDown, faArrowUp, faArrowTurnDown, faArrowTurnUp )

let gauthClientId = "1068442727155-3okdbq8lraupn9nqc1r5ogcamdaf3u4h.apps.googleusercontent.com";

const app = createApp(App)
  .component('font-awesome-icon', FontAwesomeIcon)
  .use(router)
  .use(Vue3Storage, { namespace: "pumping-tool-", storage: StorageType.Local })
  .use(vue3GoogleLogin, {
    clientId: gauthClientId
  })
  .use(Toaster)
  .use(XLSX);

//dev
/*
app.config.globalProperties.$dynamoDbLambdaUrl = "https://kloh3magyc4lc67bzwp3mmciqi0skrjp.lambda-url.eu-west-1.on.aws/";
app.config.globalProperties.$pdfLambdaUrl = "https://un7nfptd7z44x3cixpyeusdbpa0dslab.lambda-url.eu-west-1.on.aws/";
*/
app.config.globalProperties.$modelLambdaUrl = "https://6jxrzlfnxqqqwcnfre5no47cye0pbqrn.lambda-url.eu-west-1.on.aws/";

//prod
/*
app.config.globalProperties.$modelLambdaUrl = "https://voau2ldaqyagzc2bjlj6un26ze0syyyr.lambda-url.eu-west-1.on.aws/";
*/
app.config.globalProperties.$dynamoDbLambdaUrl = "https://yt4vnhptfhhvy6jezqbnn7ja6a0zuhkj.lambda-url.eu-west-1.on.aws/";
app.config.globalProperties.$pdfLambdaUrl = "https://gef3m5qubu7jwonkdsdbrfhpdm0zpwbi.lambda-url.eu-west-1.on.aws/";


app.mount('#app');
