<template>
  <!-- Sections -->
  <div class="col block me-2" style="height: fit-content;">
    <div class="block-title">Concrete informations</div>

    <div style="width: 150px;">
      <span>Minimum r² value:</span>
      <input type="number" id="project-name-input" class="form-control" :value="r2Threshold" min=0 max=100 step=0.01
        @input="handleR2ThresholdValue($event)">
    </div>

    <!-- Linear Table -->
    <div class="d-flex align-items-end justify-content-between" >
      <div class="block-title mt-4">Linear curves</div>
      <img class="pe-3" height="36" src="../../assets/tribo_linear.png" />
    </div>
    <table style="width:98%;">
      <thead>
        <th v-for="column, columnIndex of linearColumns" :key="columnIndex" :width="column.width">{{ column.label }}
        </th>
      </thead>
      <tbody>
        <tr v-for="concrete, concreteIndex of linearConcretes" :key="concreteIndex">
          <!-- Name -->
          <td>
            <div class="d-flex justify-content-center" style="position: relative">
              {{ concrete.ID }}
            </div>
          </td>
          <!-- Viscosity -->
          <td>
            <div class="d-flex justify-content-center" :class="hasViscosityWarning(concrete.ID, 'model_linear') ? 'warning' : ''"
              style="position: relative">
              {{ concrete.linear.interface_viscosity_linear }}
            </div>
          </td>
          <!-- Yield stress -->
          <td>
            <div class="d-flex justify-content-center" :class="hasYieldStressWarning(concrete.ID) ? 'warning' : ''"
              style="position: relative">
              {{ concrete.linear.interface_yield_stress_linear }}
            </div>
          </td>
          <!-- r² -->
          <td>
            <div class="d-flex justify-content-center"
              :class="hasR2Warning(concrete.ID, 'model_linear') ? 'warning' : ''" style="position: relative">
              {{ concrete.linear.r_square_linear }}
            </div>
          </td>
          <!-- Density -->
          <td>
            <div class="d-flex justify-content-center" style="position: relative">
              <div>
                {{ concrete.DensityNoAir }}   
              </div>
              <!-- Switch model and warning bubble holder -->
              <div class="extra-holder">
                <div class="switch-button" @click="setNonLinear(concrete.ID)" >
                  <div class="arrow-holder">
                    <font-awesome-icon :icon="['fas', 'arrow-turn-down']"/>
                  </div>
                </div>
                <div class="bubble" :class="hasR2Warning(concrete.ID, 'model_linear') ? 'warning' : ''" @mouseenter="showWarningPanel($event, concrete.ID, 'model_linear')"
                  v-if="hasError(concrete.ID, 'model_linear')" @mouseleave="hideWarningPanel($event, concrete.ID)">
                  <font-awesome-icon icon="fa-solid fa-circle-exclamation" />
                </div>
              </div>
            </div>
          </td>
          <!-- Actions -->
           <!--
            <td>
              <div class="d-flex justify-content-center" style="position: relative">
                <div><font-awesome-icon style="cursor: pointer;" :icon="['fas', 'arrow-turn-down']" @click="setNonLinear(concrete.ID)" /></div>
                <div class="bubble" :class="hasR2Warning(concrete.ID, 'model_linear') ? 'warning' : ''" @mouseenter="showWarningPanel($event, concrete.ID, 'model_linear')"
                v-if="hasError(concrete.ID, 'model_linear')" @mouseleave="hideWarningPanel($event, concrete.ID)">
                <font-awesome-icon icon="fa-solid fa-circle-exclamation" />
              </div>
            </div>
          </td>
          -->
        </tr>
      </tbody>
    </table>
    <!-- End linear Table -->

    <!-- Non linear Table -->
    <div class="d-flex align-items-end justify-content-between mt-4" >
      <div class="block-title ">Non-Linear curves</div>
      <img class="pe-3" height="36" src="../../assets/tribo_non_linear.png" />
    </div>
    <table style="width:98%;">
      <thead>
        <th v-for="column, columnIndex of nonLinearColumns" :key="columnIndex" :width="column.width">{{ column.label }}
        </th>
      </thead>
      <tbody>
        <tr v-for="concrete, concreteIndex of nonLinearConcretes" :key="concreteIndex">
          <!-- Name -->
          <td>
            <div class="d-flex justify-content-center" style="position: relative">
              {{ concrete.ID }}
            </div>
          </td>
          <!-- Consistency -->
          <td>
            <div class="d-flex justify-content-center" :class="hasViscosityWarning(concrete.ID, 'model_non_linear') ? 'warning': ''" style="position: relative">
              {{ concrete.nonLinear.interface_consistency_non_linear }}
            </div>
          </td>
          <!-- Yield Stress -->
          <td>
            <div class="d-flex justify-content-center" :class="hasYieldStressWarning(concrete.ID, 'model_non_linear') ? 'warning' : ''" style="position: relative">
              {{ concrete.nonLinear.interface_yield_stress_non_linear }}
            </div>
          </td>
          <!-- Interface Shear index -->
          <td>
            <div class="d-flex justify-content-center" :class="hasInterfaceShearIndexWarning(concrete.ID) ? 'warning' : ''" style="position: relative">
              {{ concrete.nonLinear.interface_shear_index }}
            </div>
          </td>
          <!-- r² -->
          <td>
            <div class="d-flex justify-content-center" :class="hasR2Warning(concrete.ID, 'model_non_linear') ? 'warning' : ''" style="position: relative">
              {{ concrete.nonLinear.r_square_non_linear }}
            </div>
          </td>
          <!-- Density -->
          <td>
            <div class="d-flex justify-content-center" style="position: relative">
              <div>
                {{ concrete.DensityNoAir }}
              </div>
              <!-- Switch model and warning bubble holder -->
              <div class="extra-holder">
                <div class="switch-button" @click="setLinear(concrete.ID)" >
                  <div class="arrow-holder">
                    <font-awesome-icon :icon="['fas', 'arrow-turn-up']"/>
                  </div>
                </div>
                <div class="bubble" :class="hasR2Warning(concrete.ID, 'model_non_linear') ? 'warning' : ''" @mouseenter="showWarningPanel($event, concrete.ID, 'model_non_linear')"
                v-if="hasError(concrete.ID, 'model_non_linear')" @mouseleave="hideWarningPanel($event, concrete.ID)">
                  <font-awesome-icon icon="fa-solid fa-circle-exclamation" />
                </div>
              </div>
            </div>
          </td>

          <!-- Actions -->
           <!--
            <td>
              <div class="d-flex justify-content-center" style="position: relative">
                <div><font-awesome-icon style="cursor: pointer;" :icon="['fas', 'arrow-turn-up']" @click="setLinear(concrete.ID)"/></div>
                <div class="bubble" :class="hasR2Warning(concrete.ID, 'model_non_linear') ? 'warning' : ''" @mouseenter="showWarningPanel($event, concrete.ID, 'model_non_linear')"
                v-if="hasError(concrete.ID, 'model_non_linear')" @mouseleave="hideWarningPanel($event, concrete.ID)">
                <font-awesome-icon icon="fa-solid fa-circle-exclamation" />
              </div>
            </div>
          </td>
          -->
        </tr>
      </tbody>
    </table>
    <!-- End linear Table -->

    <!--WARNING PANEL-->
    <div id="warning-panel">
      <div><b>Warning</b></div>
      <div class="mt-1" v-for="message, index of warningPanelMessages" :key="index">{{ message }}</div>
    </div>
  </div>

  <div class="col block ms-2" style="position:relative;">
    <div id="tribology-graph" style="width:100%;height:741px;">
    </div>
    <!-- Loading spinner animation -->
    <div v-if="isAwaitingTribologyResponse" id="spinner" class="d-flex justify-content-center align-items-center spinner-holder">
      <GradientSpinner :textColor="'black'" style="top: calc(-50px)"></GradientSpinner>
    </div>
  </div>
</template>

<script>
import GradientSpinner from '../elements/GradientSpinner.vue'

export default {
  name: 'TribologyTab',
  components: {
    GradientSpinner
  },
  props: {
    domainValidityList: Object,
    concreteDatas: Object,
    isAwaitingTribologyResponse: Boolean,
    r2Threshold: Number,
  },
  emits: [
    "r2ThresholdChanged",
    "modelOverriden"
  ],
  data() {
    return {
      yieldStressThreshold: -100,
      shearStressThreshold: 2,
      linearColumns: [
        { field: "ID", label: "Name", width: "20%" },
        { field: "InterfaceViscosity", label: "Interface Viscosity [Pa.s/m]", width: "25%" },
        { field: "YieldStress", label: "Interface Yield Stress [Pa]", width: "25%" },
        { field: "r2", label: "r²", width: "15%" },
        { field: "DensityNoAir", label: "Density", width: "15%" },
        /*
        { field: "ID", label: "Name", width: "15%" },
        { field: "InterfaceViscosity", label: "Interface Viscosity [Pa.s/m]", width: "25%" },
        { field: "YieldStress", label: "Interface Yield Stress [Pa]", width: "25%" },
        { field: "r2", label: "r²", width: "10%" },
        { field: "DensityNoAir", label: "Density", width: "15%" },
        { field: "actions", label: "Actions", width: "10%" },
         */
      ],
      nonLinearColumns: [
        { field: "ID", label: "Name", width: "20%" },
        { field: "InterfaceConsistency", label: "Interface Consistency [Pa.s/m]", width: "16.66%" },
        { field: "YieldStress", label: "Interface Yield Stress [Pa]", width: "16.67%" },
        { field: "ShearIndex", label: "Interface Shear Index", width: "16.67%" },
        { field: "r2", label: "r²", width: "15%" },
        { field: "DensityNoAir", label: "Density", width: "15%" },
        /*
        { field: "ID", label: "Name", width: "15%" },
        { field: "InterfaceConsistency", label: "Interface Consistency [Pa.s/m]", width: "16.66%" },
        { field: "YieldStress", label: "Interface Yield Stress [Pa]", width: "16.66%" },
        { field: "ShearIndex", label: "Interface Shear Index", width: "16.66%" },
        { field: "r2", label: "r²", width: "10%" },
        { field: "DensityNoAir", label: "Density", width: "15%" },
        { field: "actions", label: "Actions", width: "10%" },
         */
      ],
      hoveredWarningBubbleIndex: null,
      warningPanelMessages: [],
    }
  },
  computed: {
    linearConcretes() {
      return this.concreteDatas.filter((item) => {
        return (item.defaultModel === 'model_linear' && !item.overrideModel) || item.overrideModel === 'model_linear';
      })
    },
    nonLinearConcretes() {
      return this.concreteDatas.filter((item) => {
        return !((item.defaultModel === 'model_linear' && !item.overrideModel) || item.overrideModel === 'model_linear');
      })
    },
  },
  methods: {
    getConcreteByID(concreteID) {
      return this.concreteDatas.find(item => item.ID === concreteID);
    },
    handleR2ThresholdValue(event) {
      let value = parseFloat(event.target.value)
      if (isNaN(value)) { value = 0 }
      if (value > 100) { value = 100 }
      if (value < 0) { value = 0 }
      this.$emit("r2ThresholdChanged", value);
    },
    hasViscosityWarning(concreteID, model) {
      if (this.isAwaitingTribologyResponse) {
        return false;
      }
      const concrete = this.getConcreteByID(concreteID);
      const viscosityValue = (model === 'model_linear') ? concrete.linear.interface_viscosity_linear : concrete.nonLinear.interface_consistency_non_linear;
      return viscosityValue < 0;
    },
    hasR2Warning(concreteID, model) {
      if (this.isAwaitingTribologyResponse) {
        return false;
      }
      const concrete = this.getConcreteByID(concreteID);
      const rSquareValue = (model === 'model_linear') ? concrete.linear.r_square_linear : concrete.nonLinear.r_square_non_linear;
      return !rSquareValue || (rSquareValue < this.r2Threshold);
    },
    hasYieldStressWarning(concreteID, model) {
      if (this.isAwaitingTribologyResponse) {
        return false;
      }
      const concrete = this.getConcreteByID(concreteID);
      const yieldStressValue = (model === 'model_linear') ? concrete.linear.interface_yield_stress_linear : concrete.nonLinear.interface_yield_stress_non_linear;
      return yieldStressValue < this.yieldStressThreshold;
    },
    hasInterfaceShearIndexWarning(concreteID) {
      if (this.isAwaitingTribologyResponse) {
        return false;
      }
      const concrete = this.getConcreteByID(concreteID);
      const shearStressIndex = concrete.nonLinear.interface_shear_index;
      return shearStressIndex < this.shearStressThreshold;
    },
    hasDomainValidityWarning(concreteID) {
      try {
        if (this.domainValidityList[concreteID].warning_messages.length > 0) {
          return true;
        }
      } catch (error) {
        return false;
      }
    },
    hasError(concreteID, model) {
      return this.hasYieldStressWarning(concreteID, model) 
              || this.hasR2Warning(concreteID, model) 
              || this.hasDomainValidityWarning(concreteID, model) 
              || (this.hasInterfaceShearIndexWarning(concreteID) && model === "model_non_linear");
    },
    showWarningPanel(event, concreteID, model) {
      // get warningBubble coordinates relative to viewport
      //const element = document.getElementById(`warning-bubble-${index}`)
      const rect = event.target.getBoundingClientRect();
      // set panel position and content
      this.warningPanelMessages = [];
      if (this.hasViscosityWarning(concreteID)) {
        this.warningPanelMessages.push(`Interface viscosity should be positive`);
      }
      if (this.hasYieldStressWarning(concreteID)) {
        this.warningPanelMessages.push(`Yield stress should not be less than ${this.yieldStressThreshold}`);
      }
      if (this.hasR2Warning(concreteID, model)) {
        this.warningPanelMessages.push(`r² should not be less than ${this.r2Threshold}`);
      }
      if (this.hasInterfaceShearIndexWarning(concreteID) && model == 'model_non_linear') {
        this.warningPanelMessages.push(`Interface shears index should not be less than ${this.shearStressThreshold}`);
      }
      if (this.hasDomainValidityWarning(concreteID)) {
        const warningMessages = this.domainValidityList[concreteID].warning_messages;
        for (let warning of warningMessages) {
          this.warningPanelMessages.push(warning.message);
        }
      }
      const panel = document.getElementById("warning-panel");
      panel.style.top = `${rect.top + window.scrollY + 10}px`;
      panel.style.left = `${rect.left + 30}px`;
      panel.classList.add("active")
    },
    hideWarningPanel(event, index) {
      // hide panel
      const panel = document.getElementById("warning-panel");
      panel.classList.remove("active");
    },
    setLinear(concreteID) {
      this.$emit('modelOverriden', concreteID, 'model_linear');
    },
    setNonLinear(concreteID) {
      this.$emit('modelOverriden', concreteID, 'model_non_linear');
    },
  }
}
</script>

<style scoped>
th {
  background-color: #1A4370;
  color: white;
  height: 40px;
}

th,
td {
  padding: 4px;
  text-align: center;
}

table,
th,
td {
  border: 1px solid black;
  border-collapse: collapse;
}

td div.warning {
  color: orange;
}

.extra-holder {
  position: absolute;
  top: -17px;
  left: calc(100% + 0px);
  width: 60px;
  height: 58px;
  z-index: 1;
  display: flex;
}

.switch-button {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
}

.arrow-holder {
  cursor: pointer;
  padding: 10px 16px;
  background-color: rgba(100, 100, 100, 0.0);
  transition: background-color 0.2s ease-out;
  border-radius: 2px;
}

.arrow-holder:hover {
  background-color: rgba(100, 100, 100, 0.3);
}

.bubble {
  position: absolute;
  top: 20px;
  left: 40px;
  cursor: pointer;
  color: orange;
  display: flex;
  align-items: center;
  justify-content: center;
}


#warning-panel {
  display: none;
  min-width: 230px;
  width: fit-content;
  background-color: white;
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.12);
  border: #1A4370 solid 1px;
  border-radius: 2px;
  padding: 8px;
  height: fit-content;
  z-index: -1;
}

#warning-panel.active {
  display: flex;
  flex-direction: column;
  position: absolute;
  z-index: 300;
}
</style>