<template>
  <form @submit.prevent="send" @click="stopEvent($event)" id="concrete-form">
    <b>Concrete name: </b>
    <input id="name-input" type="text" required class="form-control" v-model="concreteName"
      style="width: 100%;" @input="validateNameInput" placeholder="Concrete name">
    <div v-if="nameErrorMessage !== ''" class="input-hint-danger">{{ nameErrorMessage }}</div>

    <span class="mt-1">Concrete density: </span>
    <input id="density-input" type="number" required class="form-control" min=0 max=10 step="0.05" v-model.number="densityNoAir" @input="onDensityChanged()"
      style="width: 150px;">
    <div v-if="densityErrorMessage !== ''" class="input-hint-danger">{{ densityErrorMessage }}</div>

    <!-- Choice radios-->
    <div class="form-check form-check-inline mt-1">
      <input class="form-check-input" type="radio" id="uploadConcreteOptions-0" :value="true" v-model="isFileUpload">
      <label class="form-check-label" for="uploadConcreteOptions-0">
        File Upload
      </label>
    </div>
    <div class="form-check form-check-inline">
      <input class="form-check-input" type="radio" id="uploadConcreteOptions-1" :value="false" v-model="isFileUpload">
      <label class="form-check-label" for="uploadConcreteOptions-1">
        Manual Input
      </label>
    </div>

    <div class="mt-1">
      <!-- file upload-->
      <template v-if="isFileUpload">
        <FileInput :fileName="fileName" @fileChanged="onConcreteFileChanged"></FileInput>
      </template>

      <!-- Manual input -->
      <template v-else>
        <div>
          <div class="row g-0 input-line d-flex justify-content-around" v-for="(item, index) of shearStressInputs" :key="index">
              <div class="input-group" style="width: 45%">
                <input class="form-control flex-grow-2" type="number" min=0 step=0.5 v-model.number="velocityInputs[index]">
                <div class="input-group-append">
                  <span class="input-group-text square" id="basic-addon2">m/s</span>
                </div>
              </div>

              <div class="input-group" style="width: 45%">
                <input class="form-control flex-grow-2" type="number" min=0.1 step=0.5 v-model.number="shearStressInputs[index]">
                <div class="input-group-append">
                  <span class="input-group-text square" id="basic-addon2">Pa</span>
                </div>
              </div>

            <font-awesome-icon class="align-self-center" icon="fa-solid fa-xmark"
              style="color:red;padding:0px;width:16px;display:inline-block;" @click="removeField(index)" />
          </div>

          <div class="btn btn-sm btn-secondary add-reading-button mt-2" @click="addField()">
            + Add
          </div>

        </div>
        <!-- End manual input -->
      </template>

    </div >
    <br />
    <button id="submit-concrete-button" class="btn btn-primary submit-button" type="button" key="submit" :disabled="isDisabled" @click="send">
      Upload concrete
    </button>

  </form>

</template>



<script>
import FileInput from '@/components/elements/FileInput'
import * as XLSX from 'xlsx';
import { hasSpecialCharacters } from "@/utils/functions" 

export default {
  name: 'UploadConcreteForm',
  components: {
    FileInput
  },
  props: {
    userUUID: {
      type: String,
      default: '',
      required: false
    },
    concretes: Object,
  },
  data() {
    return {
      defaultVelocities: [1.07, 0.93, 0.8, 0.67, 0.53, 0.4, 0.27],
      concreteName: "",
      densityNoAir: 2.35,
      velocityInputs: [1.07, 0.93, 0.8],
      shearStressInputs: [0.0, 0.0, 0.0],
      velocityFromFile: [],
      shearStressFromFile: [],
      isFileUpload: true,
      fileName: '',
      uploadConcreteOptions: [
        { label: 'Manual input', value: false },
        { label: 'File upload', value: true },
      ],
      nameErrorMessage: '',
      densityErrorMessage: '',
    }
  },
  emits: [
    'requiredConcreteUpload',
  ],
  computed: {
    isDisabled() {
      if (!this.concreteName) {
        return true;
      }
      if (this.nameErrorMessage || this.densityErrorMessage) {
        return true;
      }
      if (this.isFileUpload && (this.fileName === "" || this.velocityFromFile.length === 0 || this.shearStressFromFile.length === 0)) {
        return true;
      }
      return false;
    },
    concreteNames() {
      return this.concretes.reduce((stack, current) => {
        return [...stack, current.ID ];
      }, []);
    },
  },
  methods: {
    
    // prevents closing the window if user clicks its
    stopEvent(event){
      event.stopPropagation();
    },
    
    onDensityChanged() {
      if (this.densityNoAir < 0) {
        this.densityNoAir = 0.0;
      }
    },

    validateNameInput() {
      this.nameErrorMessage = '';
      if (this.concreteName === '') {
        this.nameErrorMessage = 'Concrete name cannot be empty';
      }
      if (hasSpecialCharacters(this.concreteName)) {
        this.nameErrorMessage = 'Concrete name contains forbidden characters';
      };
      if(this.concreteNames.includes(this.concreteName)) {
        this.nameErrorMessage = 'Another concrete exists with that name';
      }

      if (this.nameErrorMessage !== ''){
        document.getElementById('name-input').classList.add('bad-input');
        document.getElementById('submit-concrete-button').setAttribute('disabled', true);
        this.$toast.warning(this.nameErrorMessage, { position: "top", duration: 2000 });
        return;
      }
      
      this.nameErrorMessage = '';
      document.getElementById('name-input').classList.remove('bad-input');
      document.getElementById('submit-concrete-button').removeAttribute('disabled');
    },

    // triggered when add button is clicked. adds one additionnal manual inputs for shearStress and velocity
    addField() {
      this.shearStressInputs.push(0.0);
      this.velocityInputs.push(this.defaultVelocities[this.velocityInputs.length] || 0.0);
    },

    // triggered when detele manual input button is clicked. 
    // remove data for shearStress and velocity at index given in argument
    removeField(index) {
      if (this.shearStressInputs.length === 3) {
        this.$toast.warning('A minimum of 3 values is required', { position: "top", duration: 2000 });
        return;
      }
      this.shearStressInputs.splice(index, 1);
      this.velocityInputs.splice(index, 1);
    },

    async onConcreteFileChanged(event) {
      if (!(event.target.files && event.target.files[0])) {
        return;
      }
      this.fileName = event.target.value.split('\\').pop();
      if (this.concreteName === "") {
        this.concreteName = this.fileName.split('.')[0];
      }
      this.velocityFromFile = [];
      this.shearStressFromFile = [];
      // gets last segment after splitting file name on '.' character, i. e. file extension
      const extension = event.target.value.split('.').pop();
      let contents = null;
      switch (extension) {
        case 'csv':
          contents = await this.parseCSV(event.target.files[0]);
          this.processCSV(contents);
          break;
        case 'xlsx':
          contents = await this.parseXLSX(event.target.files[0]);
          try {
            this.processXLSX(contents);
          } catch (error) {
            this.$toast.error(error.message, { position: "top", duration: 5000 });
            this.fileName = "";
            this.concreteName = "";
            event.target.value = null;
            return;
          }
          break;
        default:
          this.$toast.error('Error! Supports only csv and xlsx files', { position: "top", duration: 5000 });
          break;
      }
      this.validateNameInput();

    },

    parseCSV(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = res => {
          resolve(res.target.result);
        };
        reader.onerror = err => reject(err);
        reader.readAsBinaryString(file);
      });
    },

    parseXLSX(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = res => {
          var workbook = XLSX.read(res.target.result, {
            type: 'binary'
          });
          var sheets = [];
          workbook.SheetNames.forEach(function (sheetName) {
            var XL_row_object = XLSX.utils.sheet_to_row_object_array(workbook.Sheets[sheetName]);
            sheets.push((XL_row_object));
          });
          resolve(sheets);
        };
        reader.onerror = err => reject(err);

        reader.readAsBinaryString(file);
      });
    },

    processCSV(file) {
      let lines = file.split("\n");
      // linearVelocity and shearStress are on cell C21 - D28
      // TODO check if data is not the cells it is supposed to be
      for (let i = 21; i < 28; i++) {
        let line = lines[i].split("\t");
        this.velocityFromFile.push(Number(line[2]));
        this.shearStressFromFile.push(Number(line[3]));
      };
    },
    processXLSX(file) {
      // results are in the second sheet
      // TODO check if data is not the cells it is supposed to be
      let lines = file[1];
      const hasError = false;
      const velocities = [];
      const shearStresses = [];
      for (let i = 0; i < 7; i++) {
        const velocity = Math.round(lines[i]['Linear velocity (m/s)'] * 100 + Number.EPSILON) / 100;
        const shearStress = Math.round(lines[i]['ShearStress (Pa)'] * 100 + Number.EPSILON) / 100;

        if (isNaN(velocity) || isNaN(shearStress)){
          throw new Error(`Invalid value for reading #${i+1}, please check your data file.`);
        }
        // data from excel has better precision than the one form csv, so it is harmonized by
        // rounding down to 2 decimal places (usage of Number.Epsilon to avoid some rounding errors)
        velocities.push(velocity);
        shearStresses.push(shearStress);
      };
      this.velocityFromFile = velocities;
      this.shearStressFromFile = shearStresses;
    },
    send() {
      // form inputs validations
      if (this.isFileUpload) {
        if (this.fileName === "" || this.velocityFromFile.length === 0 || this.shearStressFromFile.length === 0) {
          this.$toast.error('Please select a datasheet', { position: "top", duration: 2000 });
          return;
        }
      } else {
        if (this.velocityInputs.includes(0.0) || this.shearStressInputs.includes(0.0)) {
          this.$toast.error('Values should be non-zero', { position: "top", duration: 2000 });
          return;
        }
      }

      let payload = {
        concreteName: this.concreteName,
        densityNoAir: this.densityNoAir,
        shearStress: (this.isFileUpload) ? [...this.shearStressFromFile] : [...this.shearStressInputs], // spread operators to convert Objects to Array
        velocity: (this.isFileUpload) ? [...this.velocityFromFile] : [...this.velocityInputs],
      };

      this.$emit('requiredConcreteUpload', payload);

      // reset values
      this.concreteName = "";
      this.densityNoAir = 2.35;
      if (this.isFileUpload) {
        this.fileName = "";
        document.getElementById('concrete-file-input').value = "";
        this.velocityFromFile = [];
        this.shearStressFromFile = [];
      } else {
        for (let i = 0; i < this.shearStressInputs.length; i++) {
          this.shearStressInputs[i] = 0.0;
        };
      }
    },
  },
}

</script>

<style>
#concrete-form {
  padding: 4px 10px;
}

#name-input {
  border: black solid 1px;
}

#name-input.bad-input {
  border: orange solid 1px;
}

.fields-head {
  text-align: center;
  font-size: 14px;
}

.concrete-input {
  margin: 1px;
  display: inline-block;
}

.upload-hint {
  font-style: italic;
  color: grey;
}

.add-reading-button {
  color: white!important;
}

.file-input {
  cursor: pointer;
  border: black solid 1px;
  border-radius: 6px;
  padding: 6px 14px;
  background-color: white;
  color: black;
  transition: background-color 0.25s, color 0.25s;
}

.file-input:hover {
  color: white;
  background-color: gray;
}


</style>