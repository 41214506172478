<template>
  <!-- Top panel -->
  <div class="top-panel">
    <div>
      <img src="../../assets/HOLCIM_Logo_SmartFlow.png" />
    </div>
    <div style="background-color: white;border-radius: 4px;">
      <button type="button" class="btn button-logout btn-outline-primary" @click="logOut()">Logout</button>
    </div>
  </div>
  <!-- End Top panel -->

  <!-- Main container -->
  <div class="main-container">

    <!--  loading spinner animation -->
    <div id="main-loading-screen-holder" v-if="isWaitingReport">
      <div id="spinner" class="d-flex justify-content-center align-items-center main-spinner-holder">
        <!--
          <div class="spinner-border" style="width: 200px; height: 200px;position:absolute;"></div>
          <span style="position:relative;" class=""><b>Generating report...</b></span>
        -->
        <GradientSpinner></GradientSpinner>
      </div>
    </div>
    <!-- end loading spinner -->

    <!-- Project, customer, pump and concrete -->
    <div class="block mt-4">
      <!-- Project and customer name inputs -->
      <div class="row">
        <div class="col">
          <span>Project name:</span>
          <input type="text" id="project-name-input" style="width: 80%;" class="form-control" v-model="projectName"
            placeholder="Project name" @input="validateProjectNameInput">
          <div v-if="projectNameErrorMessage !== ''" class="input-hint-danger">{{ projectNameErrorMessage }}</div>
        </div>
        <div class="col">
          <span>Customer name:</span>
          <input type="text" id="customer-name-input" style="width: 80%;" class="form-control" v-model="customerName"
            placeholder="Customer name" @input="validateCustomerNameInput">
          <div v-if="customerNameErrorMessage !== ''" class="input-hint-danger">{{ customerNameErrorMessage }}</div>
        </div>
      </div>
      <!-- End Project and customer name inputs -->

      <!-- Pump and concrete selects -->
      <div class="row mt-3">
        <div class="col d-flex justify-content-between">
          <div class="d-flex flex-column " style="width: 85%;">
            <div v-show="currentTab == 'pump'">
              <div>
                <span>Choose your pump:</span>

                <div style="position: relative;display: inline;padding-left: 16px;" @mouseenter="openPumpInfoPanel()"
                  @mouseleave="callTimerForClosePumpInfoPanel()">
                  <!--

                    <font-awesome-icon class="pump-info-icon" v-if="hoveringPumpInfo" :icon="['fas', 'envelope']" />
                    <font-awesome-icon class="pump-info-icon" v-else :icon="['fas', 'circle-info']"  />
                  -->
                  <font-awesome-icon class="pump-info-icon" :icon="['fas', 'circle-info']" />
                  <AddPumpInfoPanel v-if="hoveringPumpInfo" @mouseenter="openPumpInfoPanel()"
                    @mouseleave="callTimerForClosePumpInfoPanel()" @emailClicked="sendPumpEmail()"></AddPumpInfoPanel>
                </div>
              </div>
              <NestedMenu :menus="sortedPumpsByProvider" :key="isLoading" :isAwaitingResponse="isAwaitingResponse"
                :defaultLabel="isLoading ? 'Loading...' : 'Select your pump'" @subMenuItemClicked="changePump" />
            </div>
          </div>
          <div class="d-flex flex-column" style="margin-right: calc(20% - 16px); margin-left: 30px;min-width: 140px;">
            <div v-show="currentTab == 'pump'">
              <span>Pump efficiency:</span>
              <div class="d-flex align-items-center">
                <input class="form-control" type="number" v-model.number="fillCoeff" min=1 max=100 step=1
                  @change="handleFillCoeffChange($event)">
                <div class="ms-1">%</div>
              </div>
            </div>
          </div>
        </div>
        <div class="col">
          <span>Choose your concrete:</span>
          <div id="concrete-select" class="concrete-select form-control" style="position: relative;width: 80%;"
            @click="openConcreteList($event);">
            {{ selectedConcreteIndexes.includes(lastConcreteIndex) ? '&check;&nbsp;' : '&nbsp;&nbsp;&nbsp;&nbsp;' }}
            {{
              concretes.length > 0 ? concretes[lastConcreteIndex].ID : 'Loading...'
            }}
            <div v-if="isConcreteListOpen" class="concrete-select-list">

              <div v-for="(item, index) of concretes" :key="index"
                class="concrete-select-option d-flex justify-content-between">
                <div class="concrete-label flex-grow-1" @click="clickConcrete($event, index)">
                  {{ selectedConcreteIndexes.includes(index) ? '&nbsp;&nbsp;&check;&nbsp;' : '&nbsp;&nbsp;&nbsp;&nbsp;'
                  }}
                  {{
                    item.ID
                  }}
                </div>
                <div class="delete-concrete-button">
                  <button class="trash-button" type="button" @click="deleteConcrete(item.concreteUUID, item.ID)">
                    <img src="../../assets/trash_empty.png" />
                  </button>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
      <!-- End Pump and concrete -->

      <!-- Piston checkbox -->

      <div class="mt-3" style="width:100%;min-height:26px;">
        <div v-show="currentTab == 'pump'">
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio" name="piston" id="piston-true" :value="true" v-model="piston"
              :disabled="!hasPiston" @change=changePiston($event)>
            <label class="form-check-label" for="piston-true">Piston mode (high pressure)</label>
          </div>
          <div class="form-check form-check-inline ms-3">
            <input class="form-check-input" type="radio" name="piston" id="piston-false" :value="false" v-model="piston"
              @change=changePiston($event)>
            <label class="form-check-label" for="piston-false">Rod mode (low pressure)</label>
          </div>
        </div>
      </div>
      <!-- End Piston checkbox -->

      <!-- Upload concrete -->
      <div class="row mt-2" style="min-height: 38px;">
        <div v-show="currentTab == 'pump'">
          <button type="button" class="btn btn-outline-primary" style="border-radius: 3px;" data-bs-toggle="collapse"
            data-bs-target="#collapsible-upload-form" @click="handleToggleConcreteForm()">
            <b>Upload concrete data</b>
          </button>
        </div>
        <div class="col-5" style="position:relative;z-index:1;">
          <div id="collapsible-upload-form" class="mt-2 mb-2 hovering-collapsible collapse">
            <UploadConcreteForm :userUUID="userUUID" :concretes="concretes" @requiredConcreteUpload="uploadConcrete"
              :key="isConcreteFormOpen">
            </UploadConcreteForm>
          </div>
        </div>
      </div>
      <!-- End Upload concrete -->
    </div>
    <!--  End Project, customer, pump and concrete -->
    <div class="row d-flex g-0 mt-4">
      <button type="button" class="tab-button" :class="{ active: currentTab === 'pump' }"
        @click="setCurrentTab('pump')">Pumpability</button>
      <button type="button" class="tab-button" :class="{ active: currentTab === 'tribology' }"
        @click="setCurrentTab('tribology')">Tribology</button>
    </div>

    <!-- Sections & Graph -->
    <div class="row g-0">
      <!-- Pumpability View -->
      <div :style="{ display: (currentTab === 'pump') ? 'flex' : 'none' }">
        <!-- Sections -->
        <div class="col block me-2" style="height: fit-content;">
          <div class="block-title">Pipeline</div>
          <div class="row g-0 d-flex flex-column">
            <span>Elevation (m):</span>
            <input v-model.number="elevation" type="number" class="form-control" min="-100" max="1000" step="1"
              @change="changeElevation($event)">
          </div>

          <PipelineSection style="margin-top:30px;" v-for="(item, index) in sections" :ref="('section' + index)"
            :key="item.id" :index="index" :initialData="item" @sectionChange="changeSection"
            @requestDelete="deleteSection">
          </PipelineSection>
          <button type="button" class="btn btn-success mt-4" :disabled="sections.length >= 5" @click="addSection()">Add
            section</button>
        </div>
        <!-- End Sections -->

        <!-- Graph -->
        <div class="col block ms-2" style="position:relative;">
          <div id="pump-graph" style="width:100%;height:741px;">
          </div>
          <!-- Loading spinner animation -->
          <div v-if="isAwaitingResponse" id="spinner"
            class="d-flex justify-content-center align-items-center spinner-holder">
            <!--
              <div class="spinner-border" style="width: 200px; height: 200px;position:absolute;"></div>
              <span style="position:relative;" class="">Please wait...</span>
            -->
            <GradientSpinner :textColor="'black'" style="top: calc(-50px)"></GradientSpinner>
          </div>
          <div class="d-flex justify-content-center">
            <button v-if="isAwaitingResponse === false" class="btn btn-outline-primary mt-1" type="button"
              @click="generatePdf()" :disabled="projectNameErrorMessage !== '' || customerNameErrorMessage !== ''">
              Download report
            </button>
            <!--
            <button v-if="isAwaitingResponse === false" class="btn btn-outline-primary mt-1" type="button"
              @click="generatePdf()" disabled>
              Download report
            </button>
            -->
          </div>
        </div>
        <!-- End Graph -->
      </div>
      <!-- End Pumpability View -->

      <!--Tribology View -->
      <div :style="{ display: (currentTab === 'tribology') ? 'flex' : 'none' }">
        <TribologyTab :isAwaitingTribologyResponse="isAwaitingTribologyResponse"
          :domainValidityList="domainValidityList" :r2Threshold="r2Threshold"
          :concreteDatas="concretes.filter((item, index) => selectedConcreteIndexes.includes(index))"
          @r2ThresholdChanged="onR2ThresholdChanged" @modelOverriden="setConcreteModel">
        </TribologyTab>
      </div>
      <!--End Tribology View -->
    </div>
    <!-- End Sections & Graph -->


    <!-- Spacing between end of main container and footer -->
    <div style="margin:30px 0px;">
    </div>

  </div>
  <!-- End Main container -->

  <!-- Footer -->
  <div class="footer" style="position:relative;">
    <div style="position:relative">
      <span>Holcim Innovation Center 2023 - </span>
      <span style="color: darkblue; font-weight: bold;cursor:pointer;" @click="copyToClipBoard()"
        @mouseenter="openContactInfoPanel()" @mouseleave="callTimerForCloseContactInfoPanel()">Contact us</span>
      <ContactInfoPanel v-if="hoveringContactInfo" @mouseenter="openContactInfoPanel()"
        @mouseleave="callTimerForCloseContactInfoPanel()"></ContactInfoPanel>
    </div>
    <div class="footer-img">
      <img src="../../assets/holcim_logo.png">
      <img src="../../assets/holcim_title.png" style="margin-left:9px">
    </div>
  </div>
  <!-- End Footer -->
</template>


<script>
import Plotly from 'plotly.js-dist-min';
import axios from 'axios';
import * as bootstrap from "bootstrap";
import { googleLogout } from "vue3-google-login";
import { useStorage } from "vue3-storage";

import PipelineSection from '@/components/pumpingCalculator/PipelineSection.vue';
import UploadConcreteForm from '@/components/pumpingCalculator/UploadConcreteForm.vue';
import NestedMenu from '@/components/pumpingCalculator/NestedMenu.vue';
import TribologyTab from '@/components/TribologyTab/TribologyTab.vue';
import AddPumpInfoPanel from './AddPumpInfoPanel.vue';
import GradientSpinner from '../elements/GradientSpinner.vue';

import * as functions from '@/utils/functions'
import * as commons from '@/utils/commons'
import { defaultConcretes } from '@/utils/defaultConcretes'
import ContactInfoPanel from './ContactInfoPanel.vue';

export default {
  name: 'PumpingCalculator',
  components: {
    PipelineSection,
    UploadConcreteForm,
    NestedMenu,
    TribologyTab,
    AddPumpInfoPanel,
    ContactInfoPanel,
    GradientSpinner,
  },
  data() {
    return {
      isWaitingReport: false,
      isLoading: true,
      hasResponse: false,
      userEmail: "",
      userUUID: "",
      concretePlotColors: [
        '#04bbf1', // holcim bright blue
        '#1d4370', // holcim dark blue
        '#94c12e', // holcim bright green
        '#eb6400', // holcim orange
        // keep in case more are lines nedded
        /*
        '#461e6e', // holcim violet
        '#e60075', // holcim fuschia
        */
      ],
      // variables from user inputs
      r2Threshold: 0.95,
      pump: { piston: [] },
      fillCoeff: 80, // pump efficiency
      lastConcreteIndex: 0,
      selectedConcreteIndexes: [],
      errorConcretesIndexes: [], // concrete indexes which have error
      isConcreteListOpen: false,
      sectionCount: 0,
      sections: [
        {
          id: 0,
          pipeDiameter: 125,
          pipeLength: 100,
          reducerLength: 0,
          nbElbows90_250: 0,
          nbElbows90_1000: 0,
          nbElbows45: 0,
          nbElbows30: 0,
          elbows: [],
        },
      ],
      sortedPumpsByProvider: [
        { label: 'Putzmeister', children: [] },
        { label: 'Sany', children: [] },
        { label: 'Schwing', children: [] },
        { label: 'Others', children: [] },
      ],
      selectedPumpIndex: 0,
      elevation: 10,
      piston: false,
      previouslySelectedPiston: false,
      projectName: '',
      customerName: '',
      isAwaitingResponse: true,
      isAwaitingTribologyResponse: false,
      // stored data from database
      pumps: [],
      concretes: [],
      // parameters for graph display
      layout: commons.layout,
      tribologyLayout: commons.tribologyLayout,
      pumpPlot: commons.pumpPlot,
      concretePlots: commons.concretePlots,
      areaPlot: commons.areaPlot,
      safetyEnvelop: [[], []], // stored from response to send it back to pdfreport lambda
      // end of parameters for graph display
      updateTimeoutID: undefined,
      // displayed error if input is incorrect
      projectNameErrorMessage: '',
      customerNameErrorMessage: '',
      currentTab: 'pump',  // 'pump', 'tribology',
      domainValidityList: {},
      isConcreteFormOpen: false,
      hoveringPumpInfo: false,
      hoveringContactInfo: false,
      closePumpInfoPanelTimerID: null,
      closeContactInfoPanelTimerID: null,
      yieldStressThreshold: -100,
      overridenModels: {} // dictionnary of concreteID : overriden models
    };
  },

  computed: {
    hasPiston() {
      return this.pump.piston.length > 0;
    },
    concreteModels() {
      return this.concretes.reduce((stack, current) => {
        stack[current.ID] = current.overrideModel || current.defaultModel;
        return stack;
      }, {})
    },
  },
  async created() {
    document.body.addEventListener('click', () => this.onBodyClick());
    this.userEmail = this.$storage.getStorageSync('userData').email;
    this.userUUID = await this.fetchUserUUID();

    // retrieve all pumps and user's concretes from database
    this.pumps = await this.fetchPumps();
    if (this.pumps.length > 0) {
      this.sortPumpsByProvider();
      const pumpIndex = this.sortedPumpsByProvider[0].children[0].index
      this.pump = this.pumps[pumpIndex]
    }
    this.isLoading = false;

    let retrievedConcretes = await this.fetchConcretes(this.userUUID);
    this.concretes = [...retrievedConcretes, ...defaultConcretes];
    if (this.concretes.length > 0) {
      this.selectedConcreteIndexes = [0];
    }
    if (this.pumps.length > 0 && this.concretes.length > 0) {
      this.getPlots();
    }
    // opens first section
    setTimeout(() => {
      this.toggleCollapsible(0);
    }, 2);

  },
  methods: {
    logOut() {
      const storage = useStorage();
      storage.removeStorageSync("exp_date");
      storage.removeStorageSync("is_logged");
      storage.clearStorageSync();
      googleLogout();
      this.$router.push('/');
    },

    onBodyClick() {
      this.closeConcreteList();
      if (!!this.$refs.pumpsMenu) {
        this.$refs.pumpsMenu.closeMenuList();
      }

      for (let i = 0; i < this.sections.length; i++) {
        this.$refs[`section${i}`][0].closeElbowList();
      }
      let el = document.getElementById(`collapsible-upload-form`);
      if (el.classList.contains('show')) {
        new bootstrap.Collapse(el)
        setTimeout(() => {
          this.isConcreteFormOpen = !this.isConcreteFormOpen;
        }, 400);
      }
    },

    handleToggleConcreteForm() {
      if (this.isConcreteFormOpen) {
        setTimeout(() => {
          this.isConcreteFormOpen = !this.isConcreteFormOpen;
        }, 400);
      } else {
        this.isConcreteFormOpen = !this.isConcreteFormOpen;
      }
    },

    openContactInfoPanel() {
      if (this.closeContactInfoPanelTimerID) {
        clearTimeout(this.closeContactInfoPanelTimerID);
      }
      this.closeContactInfoPanelTimerID = null;
      this.hoveringContactInfo = true
    },

    callTimerForCloseContactInfoPanel(immediate = false) {
      if (this.closeContactInfoPanelTimerID) {
        clearTimeout(this.closeContactInfoPanelTimerID);
      }
      this.closeContactInfoPanelTimerID = setTimeout(() => {
        this.hoveringContactInfo = false;
      }, (immediate === false) ? 200 : 5);
    },

    openPumpInfoPanel() {
      if (this.closePumpInfoPanelTimerID) {
        clearTimeout(this.closePumpInfoPanelTimerID);
      }
      this.closePumpInfoPanelTimerID = null;
      this.hoveringPumpInfo = true
    },

    callTimerForClosePumpInfoPanel(immediate = false) {
      if (this.closePumpInfoPanelTimerID) {
        clearTimeout(this.closePumpInfoPanelTimerID);
      }
      this.closePumpInfoPanelTimerID = setTimeout(() => {
        this.hoveringPumpInfo = false;
      }, (immediate === false) ? 200 : 5);
    },

    copyToClipBoard() {
      navigator.clipboard.writeText("hdcs-apps.support@holcim.com");
      this.$toast.success('Copied to clipboard!', { position: "top", duration: 2000 });
    },

    sendPumpEmail() {
      window.open("mailto:hdcs-apps.support@holcim.com?subject=SmartFlow: New pump proposal")
    },

    async fetchUserUUID() {
      const apiRequest = {
        'operation': 'create_or_fetch_user',
        'data': {
          'email': this.$storage.getStorageSync('userData').email,
          'userName': this.$storage.getStorageSync('userData').given_name,
          'userSurname': this.$storage.getStorageSync('userData').family_name
        }
      };

      try {
        const resp = await axios.post(this.$dynamoDbLambdaUrl, apiRequest);
        return resp.data.body.userUUID;
      } catch (err) {
        this.$toast.show(`Server error while retrieving user: ${err}`);
        console.error(err);
      }
    },

    async fetchPumps() {
      const apiRequest = { 'operation': 'scan_pump' };
      try {
        const resp = await axios.post(this.$dynamoDbLambdaUrl, apiRequest);
        if (parseInt(JSON.parse(resp.status)) === 200) {
          var result = resp.data.body;
          return result;
        } else {
          throw new Error('Input error: ' + resp.errors);
        }
      } catch (err) {
        this.$toast.error('Server error while fetching pumps', { position: "top", duration: 2000 });
        console.error(err);
        return [];
      }
    },

    async fetchConcretes() {
      const apiRequest = { 'operation': 'fetch_concrete', 'data': { 'userUUID': this.userUUID } };
      try {
        const resp = await axios.post(this.$dynamoDbLambdaUrl, apiRequest);
        if (parseInt(JSON.parse(resp.status)) === 200) {
          var formatedList = [];
          resp.data.body.concrete.forEach(item => {
            formatedList.push({
              ID: item.concreteName,
              DensityNoAir: item.densityNoAir || 2.35,
              Velocity: item.velocity,
              ShearStress: item.shearStress,
              InterfaceViscosity: item.coef_a,
              YieldStress: item.coef_b,
              concreteUUID: item.concreteUUID,
              linear: {},
              nonLinear: {},
            });
          });
          return formatedList;
        } else {
          throw new Error();
        }
      } catch (err) {
        this.$toast.error('Server error while fetching concretes', { position: "top", duration: 2000 });
      }

    },

    async deleteConcrete(concreteUUID, concreteName) {
      if (window.confirm(`Delete concrete '${concreteName}' ?`) == true) {
        try {
          const apiRequest = { 'operation': 'delete_concrete', 'data': { 'userUUID': this.userUUID, concreteUUID } };
          const resp = await axios.post(this.$dynamoDbLambdaUrl, apiRequest);

          // get deleted concrete index in list; 
          let deletedConcreteIndex = 0;
          for (let i = 0; i < this.concretes.length; i++) {
            if (concreteUUID === this.concretes[i].concreteUUID) {
              deletedConcreteIndex = i;
            }
          }
          let indexOfSelected = -1;

          for (let i = 0; i < this.selectedConcreteIndexes.length; i++) {
            let current = this.selectedConcreteIndexes[i];
            // if was selected, memorize index
            if (current === deletedConcreteIndex) {
              indexOfSelected = i;
            } else if (current > deletedConcreteIndex) {
              // reduce by 1 all other selected concretes indices if they were higher than deletedIndex{}
              this.selectedConcreteIndexes[i] -= 1;
            }
          }
          // if was last clicked concrete, sets it at 0
          if (this.lastConcreteIndex == deletedConcreteIndex) {
            this.lastConcreteIndex = 0;
          }
          // if it was selected, unselect it
          if (indexOfSelected !== -1) {
            this.selectedConcreteIndexes.splice(indexOfSelected, 1);
          }
          // remove concrete from list
          this.concretes.splice(deletedConcreteIndex, 1);
          // redraw graph
          await this.getPlots();
        } catch (error) {
          this.$toast.error('Error while deleting concrete: ', error, { position: "top", duration: 2000 });
        }

      } else {
        console.log("cancel concrete deletion");
      }
    },

    setCurrentTab(tabName) {
      this.currentTab = tabName;
      if (this.currentTab == "tribology") {
        this.getTribology();
      }
      document.activeElement.blur();
    },

    async submit() {
      try {
        await this.getPlots();
        this.hasResponse = true;
      } catch (e) {
        console.log(e);
      }
    },

    async handleFillCoeffChange(event) {
      let value = parseFloat(event.target.value)
      if (isNaN(value)) { value = 1 }
      if (value > 100) { value = 100 }
      if (value <= 0) { value = 1 }
      this.fillCoeff = value;
      this.getPlots();
    },

    onR2ThresholdChanged(value) {
      this.r2Threshold = parseFloat(value);
      this.getPlots();
    },

    async getPlots() {
      // check if all data is set and correct
      if (!this.pump || this.concretes.length === 0) {
        return;
      }
      if (Object.keys(this.pump).length === 0) {
        return;
      }
      if (this.selectedConcreteIndexes.length === 0) {
        this.$toast.warning('Please select at least one concrete', { position: "top", duration: 2000 });
        return;
      } else {
        // intialize reponses values
        this.errorConcretesIndexes = [];
        this.concretePlots = [];
        this.domainValidityList = {};

        this.isAwaitingResponse = true;
        await this.getTribology();

        for (let i = 0; i < this.selectedConcreteIndexes.length; i++) {
          try {
            const concreteIndex = this.selectedConcreteIndexes[i];
            if (this.errorConcretesIndexes.includes(concreteIndex)) {
              continue;
            }
            const concrete = this.concretes[this.selectedConcreteIndexes[i]];
            let dicModel = this.buildDicModel(this.concretes[this.selectedConcreteIndexes[i]]);
            let response = await axios.post(this.$modelLambdaUrl, dicModel);
            if (!response.data.body) {
              throw new Error(`Server error while computing pumpability for concrete '${concrete.ID}'`);
            }
            const data = response.data.body.results

            // pump plot
            const modeText = this.piston === true ? 'piston mode' : 'plug-flow mode'
            this.pumpPlot.name = `Max Pressure ${this.pump.pumpName} (${modeText})`;
            this.pumpPlot.x = data['pump_envelop'][0];
            this.pumpPlot.y = data['pump_envelop'][1];
            this.safetyEnvelop = data['safety_envelop'];
            this.areaPlot.x = data['pump_envelop'][0].concat(data['safety_envelop'][0].reverse());
            this.areaPlot.y = data['pump_envelop'][1].concat(data['safety_envelop'][1].reverse());

            this.concretePlots.push({
              x: data['concrete_pressure'][0], y: data['concrete_pressure'][1],
              name: `${this.concretes[this.selectedConcreteIndexes[i]]['ID']}`,
              mode: 'lines',
              line: { color: this.concretePlotColors[i], width: 3 },
              hoverlabel: { namelength: -1 },
              legendgroup: 'concrete',
            });
            this.layout.title.text = "Concrete Pressure Simulation";
          } catch (error) {
            console.log(error)
            this.$toast.error(error.message, { position: "top", duration: 2000 });
          }
        }

        // Draw pump graph
        const maxFlow = this.pumpPlot.x[this.pumpPlot.x.length - 1]
        this.layout.xaxis.range = [0, maxFlow * 1.1];

        Plotly.newPlot("pump-graph", {
          data: [this.pumpPlot, this.areaPlot, ...this.concretePlots],
          layout: this.layout,
          config: { responsive: true },
        })

        this.isAwaitingResponse = false;
      }
    },

    async getTribology() {
      const tribologyPlots = [];
      this.isAwaitingTribologyResponse = true;
      let concreteCount = 0;
      for (const concreteIndex of this.selectedConcreteIndexes) {
        try {
          let { InterfaceViscosity, YieldStress, r2, ...concreteData } = this.concretes[concreteIndex];
          const payload = {
            method: "concreteTribology",
            study: {
              Concrete: concreteData,
            }
          }
          const response = await axios.post(this.$modelLambdaUrl, payload);
          const errors = response.data.body.domain_validity.error_messages;
          if (errors.length > 0) {
            this.errorConcretesIndexes.push(concreteIndex);
            continue;
          }
          const data = response.data.body.results.concrete_tribology;
          const linearYieldStress = data.model_linear.interface_yield_stress_linear;
          const linearR2 = data.model_linear.r_square_linear;
          if (linearYieldStress < this.yieldStressThreshold || linearR2 < this.r2Threshold) {
            this.errorConcretesIndexes.push(concreteIndex);
          }

          // register tribometryParameter in concrete
          this.concretes[concreteIndex].defaultModel = data.concrete_tribology_default_model;
          this.concretes[concreteIndex].overrideModel = this.overridenModels[this.concretes[concreteIndex].ID] || '';
          this.concretes[concreteIndex].linear = data.model_linear;
          this.concretes[concreteIndex].nonLinear = data.model_non_linear;

          // add line for concrete tribology
          const tribologyPlot = {
            x: this.concretes[concreteIndex].Velocity,
            y: this.concretes[concreteIndex].ShearStress,
            name: this.concretes[concreteIndex].ID,
            mode: 'lines+markers',
            marker: {
              color: this.concretePlotColors[concreteCount],
              size: 12
            },
            line: {
              color: this.concretePlotColors[concreteCount],
              width: 4
            },
            showlegend: true
          }
          tribologyPlots.push(tribologyPlot);

          // add linear dashed line for concrete tribology
          // linear formula: shear Stress = a * velocity + b  , with a => Interface viscosity; b => Interface Yield stress
          const xPoints = [
            tribologyPlot.x[0],
            tribologyPlot.x.slice(-1)[0],
          ];

          let viscosityValue = null;
          let yieldStressValue
          if (data.concrete_tribology_default_model === 'model_linear') {
            viscosityValue = data.model_linear.interface_viscosity_linear;
            yieldStressValue = data.model_linear.interface_yield_stress_linear;
          } else {
            viscosityValue = data.model_non_linear.interface_consistency_non_linear;
            yieldStressValue = data.modelmodel_non_linear_linear.interface_yield_stress_non_linear;
          }

          const yPoints = [
            viscosityValue * xPoints[0] + yieldStressValue,
            viscosityValue * xPoints[1] + yieldStressValue,
          ]

          const linearPlot = {
            x: xPoints,
            y: yPoints,
            name: `${this.concretes[concreteIndex].ID} linear model`,
            mode: 'lines',
            line: {
              dash: 'dash',
              color: this.concretePlotColors[concreteCount],
              width: 2
            },
            showlegend: true
          }
          tribologyPlots.push(linearPlot);

          concreteCount += 1;

        } catch (error) {
          console.log(error);
        }
      };
      this.isAwaitingTribologyResponse = false;
      Plotly.newPlot("tribology-graph", {
        data: tribologyPlots,
        layout: this.tribologyLayout,
      })
    },

    validateProjectNameInput() {
      this.projectNameErrorMessage = '';
      if (functions.hasSpecialCharacters(this.projectName)) {
        this.projectNameErrorMessage = 'Project name contains forbidden characters';
      };
      if (this.projectNameErrorMessage !== '') {
        document.getElementById('project-name-input').classList.add('bad-input');
        return;
      }
    },

    validateCustomerNameInput() {
      this.customerNameErrorMessage = '';
      if (functions.hasSpecialCharacters(this.customerName)) {
        this.customerNameErrorMessage = 'Customer name contains forbidden characters';
      };
      if (this.customerNameErrorMessage !== '') {
        document.getElementById('project-name-input').classList.add('bad-input');
        return;
      }
    },

    sortPumpsByProvider() {
      // temporary, dynamic provider list fetched from DB? or provider field added to pump table?
      var providers = ['putzmeister', 'sany', 'schwing', 'others']
      for (let i = 0; i < this.pumps.length; i++) {
        let providerName = this.pumps[i].pumpName.split(' ')[0].toLowerCase();
        let providerIndex = providers.indexOf(providerName);
        if (providerIndex < 0) {
          providerIndex = providers.length - 1;
        }
        this.sortedPumpsByProvider[providerIndex].children.push({ label: this.pumps[i].pumpName, index: i })
      }
    },

    changePump(index) {
      this.pump = this.pumps[index];
      // unchecks pump mode checkbox if pump has no piston, else set checkbox as last check status that was used
      this.piston = (this.pump.piston.length === 0) ? false : this.previouslySelectedPiston;

      this.callTimerForUpdate();
    },

    openConcreteList(event) {
      event.stopPropagation();
      if (this.isAwaitingResponse) {
        return;
      }
      this.isConcreteListOpen = true;
    },

    closeConcreteList() {
      this.isConcreteListOpen = false;
    },

    clickConcrete(event, index) {
      event.stopPropagation();
      let position = this.selectedConcreteIndexes.indexOf(index)
      if (position === -1) {

        let maxLength = this.concretePlotColors.length;
        if (this.selectedConcreteIndexes.length < maxLength) {
          this.lastConcreteIndex = index;
          this.selectedConcreteIndexes.push(index);
        }
        else {
          this.$toast.error(`Cannot select more than ${maxLength} concretes`, { position: "top", duration: 2000 });
          return;
        }
      } else {
        this.selectedConcreteIndexes.splice(position, 1);
      }

      this.closeConcreteList();
      this.getPlots();
    },

    changePiston(e) {
      this.previouslySelectedPiston = (e.target.value == "true" || e.target.value == true);
      this.callTimerForUpdate();
    },

    addSection() {
      this.hideAllSections();
      this.sectionCount += 1;
      this.sections.push({
        id: this.sectionCount,
        pipeDiameter: 125,
        pipeLength: 100,
        reducerLength: 0,
        nbElbows90_250: 0,
        nbElbows90_1000: 0,
        nbElbows45: 0,
        nbElbows30: 0,
        elbows: [],
      });
      setTimeout(() => {
        this.toggleCollapsible(this.sections.length - 1);
      }, 2);
      this.callTimerForUpdate();

    },

    deleteSection(index) {
      this.sections.splice(index, 1);
      this.callTimerForUpdate();
    },

    changeSection(sectionId, sectionData) {
      // ensure elevation in smaller or equal than total pipes + connectors length
      let totalLength = 0;
      for (const section of this.sections) {
        totalLength += section.pipeLength + section.reducerLength;
      }
      if (this.elevation > totalLength) {
        this.elevation = totalLength;
      }
      this.callTimerForUpdate();
    },

    changeElevation(event) {
      let newValue;
      if (event.target.value === "") {
        newValue = 0;
      } else {
        newValue = parseFloat(event.target.value);
      }
      if (isNaN(newValue)) {
        return;
      }

      // ensure elevation absolute length is smaller or equal than total pipes + connectors length
      let totalLength = 0;
      for (const section of this.sections) {
        totalLength += section.pipeLength + section.reducerLength;
      }
      if (newValue > totalLength) {
        this.elevation = totalLength;
      } else {
        this.elevation = newValue;
      }
      this.callTimerForUpdate();
    },

    setConcreteModel(concreteID, model) {
      const concreteIndex = this.concretes.findIndex((item) => item.ID == concreteID);
      this.concretes[concreteIndex].overrideModel = model;
      this.overridenModels[concreteID] = model;
      this.getPlots();
    },

    // creates or refreshes the timer preventing successive lambda calls
    // only when timer reaches timeout, the getPlots method responsible for lambda call is invoked
    callTimerForUpdate() {
      if (typeof this.updateTimeoutID === 'number') {
        clearTimeout(this.updateTimeoutID);
      }
      this.updateTimeoutID = setTimeout(async () => await this.getPlots(), 100);
    },

    // collapses all .collapse elements
    hideAllSections() {
      for (let i = 0; i < this.sections.length; i++) {
        let el = document.getElementById(`collapse-section-${i}`);
        if (el.classList.contains('show')) {
          new bootstrap.Collapse(el)
        }
      }
    },

    toggleCollapsible(index) {
      let element = document.getElementById(`collapse-section-${index}`);
      new bootstrap.Collapse(element);
    },

    // returns a dicmodel object based the concreteID parameter, the pump and the pumpline
    buildDicModel(concrete) {
      let sections = [];
      this.sections.forEach(item => {
        sections.push({
          SectionName: `Section_${item.id + 1}`,
          PipeDiam: item.pipeDiameter,
          PipeLength: item.pipeLength + item.reducerLength,
          NbElbows90_250: item.nbElbows90_250,
          NbElbows90_1000: item.nbElbows90_1000,
          NbElbows45: item.nbElbows45,
          NbElbows30: item.nbElbows30,
        })
      })
      let { InterfaceViscosity, YieldStress, r2, ...concreteData } = concrete;
      const dicModel = {
        method: "pumpingSimulation",
        sub_method: concrete.overrideModel || concrete.defaultModel,
        study: {
          Pump: {
            FillCoeff: this.fillCoeff / 100.0,
            PumpModel: this.pump.pumpName,
            PumpPower: this.pump.power,
            PumpModeSelect: this.piston === true ? 'Piston' : 'Rod',
            PumpModes: {
              Rod: {
                MaxPressure: this.pump.rod[0],
                MaxOutput: this.pump.rod[1]
              },
              Piston: {
                MaxPressure: this.pump.piston[0],
                MaxOutput: this.pump.piston[1]
              }
            }
          },
          Concrete: { ...concreteData },
          PumpLine: {
            Elevation: this.elevation,
            SectionsList: sections,
          }
        },
      };
      let isLinear = true;
      if (Object.keys(this.overridenModels).includes(concrete.ID)) {
        isLinear = this.overridenModels[concrete.ID] == "model_linear"
      } else {
        isLinear = concrete.defaultModel == "model_linear"
      }
      if (isLinear) {
        dicModel.study.Concrete.default_r2_linear_min = this.r2Threshold
      } else {
        dicModel.study.Concrete.default_r2_non_linear_min = this.r2Threshold
      }
      return dicModel;
    },

    async uploadConcrete(data) {
      const payload = { 'operation': 'create_concrete', 'data': { 'userUUID': this.userUUID, ...data } };

      try {
        const resp = await axios.post(this.$dynamoDbLambdaUrl, payload);
        if (parseInt(JSON.parse(resp.status)) !== 200 || !!resp.data.errorMessage) {
          throw new Error();
        }
      } catch (error) {
        this.$toast.error('Server error while uploading concrete data', { position: "top", duration: 2000 });
        console.log(error);
        return;
      };
      this.$toast.success('Upload complete!', { position: "top", duration: 2000 });
      let retrievedConcretes = await this.fetchConcretes(this.userUUID);
      this.concretes = [...retrievedConcretes, ...defaultConcretes];
      // find created concrete index and selects it
      const newConcreteIndex = this.concretes.findIndex((item) => {
        return item.ID === data.concreteName;
      });
      this.selectedConcreteIndexes = [newConcreteIndex];
      this.getPlots();
    },

    async generatePdf() {
      // format pump data
      let pumpEnvelop = [];
      for (let i = 0; i < this.pumpPlot.x.length; i++) {
        pumpEnvelop.push([this.pumpPlot.x[i], this.pumpPlot.y[i]])
      }

      // format safety area data
      let pumpEnvelopSafe = [];
      for (let i = 0; i < this.safetyEnvelop[0].length; i++) {
        pumpEnvelopSafe.push([this.safetyEnvelop[0][i], this.safetyEnvelop[1][i]]);
      }
      pumpEnvelopSafe.reverse();

      // format concrete data
      const concretes = [];
      //const errorIndexes = JSON.parse(JSON.stringify(this.errorConcretesIndexes))

      const colorCodes = ['holcim_light_blue', 'holcim_deep_blue', 'holcim_green', 'holcim_orange']
      for (let i in this.selectedConcreteIndexes) {
        const concreteIndex = this.selectedConcreteIndexes[i];
        if (this.errorConcretesIndexes.includes(concreteIndex)) {
          continue;
        }
        let concrete = this.concretes[concreteIndex];
        console.log(concrete)
        let concretePlot = this.concretePlots.find((item) => item.name === concrete.ID)
        let concretePressure = [];
        for (let j = 0; j < concretePlot.x.length; j++) {
          concretePressure.push([concretePlot.x[j], concretePlot.y[j]]);
        }
        let isLinear = true;
        if (Object.keys(this.overridenModels).includes(concrete.ID)) {
          isLinear = this.overridenModels[concrete.ID] == "model_linear"
        } else {
          isLinear = concrete.defaultModel == "model_linear"
        }
        concretes.push({
          Id: concrete.ID,
          Color: colorCodes[i],
          ModelSelected: isLinear ? "model_linear" : "model_non_linear",
          ConcretePressure: concretePressure,
          ConcreteTribology: {
            Velocity: concrete.Velocity,
            ShearStress: concrete.ShearStress,
          },
          ConcreteTribologyParameters: {
            model_linear: {
              ...concrete.linear,
              /*
              interface_viscosity_linear: 1094,
              interface_yield_stress_linear: 138,
              r_square_linear: 0.999
              */
            },
            model_non_linear: {
              ...concrete.nonLinear,
              /*
              interface_yield_stress_non_linear: null,
              interface_consistency_non_linear: null,
              interface_shear_index: null,
              r_square_non_linear: null
              */
            }
            /*
            // temporary fallback to default values, for the default concrete list
            InterfaceViscosity: concrete.InterfaceViscosity,
            YieldStress: concrete.YieldStress,
            r2: concrete.r2,
            */
          }
        })
      }
      if (concretes.length == 0) {
        this.$toast.error(`No valid concrete selected`, { position: "top", duration: 4000 });
        return;
      }
      // format pipeline sections data
      const sectionList = []
      for (let i = 0; i < this.sections.length; i++) {
        var section = this.sections[i]
        sectionList.push({
          PipeDiam: section.pipeDiameter,
          PipeLength: section.pipeLength,
          ConnectorLength: section.reducerLength || null,
          NbElbows90_250: section.nbElbows90_250,
          NbElbows90_1000: section.nbElbows90_1000,
          NbElbows45: section.nbElbows45,
          NbElbows30: section.nbElbows30,
        })
      }
      const reportPayload = {
        body: {
          ProjectName: this.projectName,
          CustomerName: this.customerName,
          Pump: {
            PumpModel: this.pump.pumpName,
            PumpEnvelop: pumpEnvelop,
            PumpEnvelopSafe: pumpEnvelopSafe,
            PumpModeSelect: (this.piston) ? "Piston" : "Rod",
            PumpPower: this.pump.power,
            FillCoeff: this.fillCoeff / 100.0,
            Rod: {
              MaxPressure: this.pump.rod[0],
              MaxOutput: this.pump.rod[1],
            },
            Piston: {
              MaxPressure: this.pump.piston[0],
              MaxOutput: this.pump.piston[1],
            },
          },
          Concretes: concretes,
          Pumpline: {
            Elevation: this.elevation,
            SectionsList: sectionList,
          }
        },
        template_name: "smartflow",
      };

      try {
        this.isWaitingReport = true;
        const result = await axios.post(this.$pdfLambdaUrl, reportPayload);
        if (!result) {
          throw new Error('Server encountered a problem, please try again');
        }
        if (result.data.error_messages.length > 0) {
          throw new Error('Incorrect data');
        }
        // create hidden link with pdf data as target
        const base64pdfData = result.data.body.results.pdfreport;
        const link = document.createElement('a');
        link.download = 'pumpability_report.pdf';
        link.href = 'data:application/octet-stream;base64,' + base64pdfData;
        // emulate click on link to trigger download
        link.click();

      } catch (error) {
        this.isWaitingReport = false;
        this.$toast.error(`Error while generating report: ${error}`, { position: "top", duration: 6000 });
        throw new Error(error)

      }
      this.isWaitingReport = false;
    },
  },
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.top-panel {
  position: absolute;
  top: 0;
  left: 0;
  height: 113px;
  width: 100%;
  background: linear-gradient(90deg, #94C22C 0%, #04BBF1 43.23%, #20BBEF 55.73%, #214773 100%);
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0px 6px 8px rgba(26, 67, 112, 0.1);
  padding: 0px 50px
}

.button-logout {
  padding: 15px 24px !important;
  border-radius: 2px !important;
  font-weight: bold !important;
}

.main-container {
  margin-top: 115px;
  margin-left: 52px;
  margin-right: 52px;
}

#main-loading-screen-holder {
  pointer-events: none;
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.33);
  opacity: 1;
}

.main-spinner-holder {
  width: 100%;
  height: 100%;
  position: absolute;
  color: white;
  top: 0px;
  left: 0px;
  opacity: 1;
}


.block {
  border-radius: 4px;
  background-color: white;
  padding: 15px 30px;
}

input[type=checkbox],
input[type=checkbox]:checked {
  accent-color: #1A4370;
  border-radius: 3px;
  cursor: pointer;
}

.block-title {
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: #1A4370;
}

.pump-info-icon {
  color: grey;
  cursor: pointer;
}


.property-label {
  background-color: rgb(29, 67, 112) !important;
  color: white;
  font-size: 16px;
}

.concrete-select-list {
  position: absolute;
  top: 35px;
  left: 0;
  right: 0;
  width: 100%;
  border: solid 1px grey;
  display: flex;
  flex-direction: column;
  z-index: 10;
  box-shadow: 0px 2px 2px rgba(100, 100, 100, 0.25);
}

.concrete-select-option {
  z-index: 11;
  background-color: white;
}

.concrete-select-option:hover {
  background-color: #eef8ff;
  color: black;
  cursor: pointer;
}

.hovering-collapsible {
  position: absolute;
  background-color: white;
  border: solid 1px;
  padding: 4px;
}

#collapsible-upload-form {
  box-shadow: rgba(0, 0, 0, 0.15) 1px 3px 3px;
}

select {
  font-family: 'Font Awesome 5 Solid', 'arial'
}

.spinner-holder {
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 1);
  position: absolute;
  top: 0px;
  left: 0px;
}

.tab-button {
  position: relative;
  padding: 15px;
  width: 100px !important;
  border-radius: 0;
  border: #BFB9B4 1px solid;
  border-bottom: none;
  border-left: none;
  background-color: white;
  color: #1A4370;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  box-sizing: border-box;
  transition: background-color 0.2s;
}

.tab-button.active {
  border-top: #1A4370 4px solid;
  padding-top: 12px;
  background-color: white;
}

.tab-button:not(.active):hover {
  background-color: #1a447022;
}


.footer {
  height: 114px;
  width: 100%;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #76706B;
  box-shadow: 0px -6px 8px rgba(26, 67, 112, 0.1);
}

.footer-img {
  position: absolute;
  left: 50px;
  height: 36px;
}

.trash-button,
.trash-button:active,
.trash-button:focus {
  opacity: 0.4;
  color: #1A4370;
  background-color: rgba(0, 0, 0, 0);
  border: none;
  outline: none;
  transition: opacity 0.15s;
}

.trash-button:hover {
  opacity: 1.0;
}
</style>
